import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ReactPlayer from 'react-player/lazy';
import { AspectRatio } from 'common';

import type { PlayerTypes } from './Player.types';

const useStyles = makeStyles((theme) => ({
	player: {
		backgroundColor: theme.palette.common.black,
		margin: '0px auto'
	}
}));

const Player = (props: PlayerTypes): JSX.Element => {
	const classes = useStyles();
	const {
		url,
		startAtSeconds,
		thumbnailUrl,
		width = '100%',
		height = '100%',
		controls = true,
		playsinline = true,
		playing = true,
		loop = false,
		className = classes.player,
		...others
	} = props;

	const [playerVolume] = useState<number>(1);
	const handleOnReady = (player: ReactPlayer) => {
		if (startAtSeconds != null) {
			player.seekTo(startAtSeconds, 'seconds');
		}
	};

	return (
		<AspectRatio>
			<ReactPlayer
				volume={playerVolume}
				url={url}
				playsinline={playsinline}
				playing={playing}
				controls={controls}
				loop={loop}
				width={width}
				height={height}
				className={className}
				light={thumbnailUrl}
				onReady={handleOnReady}
				data-testid="video-player"
				{...others}
			/>
		</AspectRatio>
	);
};

export default Player;
