import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Typography } from 'components';

import useStyles from './AddonContentProgress.styles';
import classnames from 'classnames';
import { CourseRatingLink } from '../../containers/Content/OnDemand/components/RatingLinks';

type Progresses = Array<{
	contentProgress?:
		| {
				completed?: boolean | null | undefined;
		  }
		| null
		| undefined;
}>;

const getProgress = (schedule: Progresses = []) => {
	const numberOfCompletedLectures = schedule.filter(
		(schedule) => schedule.contentProgress?.completed
	).length;

	const numberOfSchedules = schedule.length || 1;

	return Math.trunc(100 * (numberOfCompletedLectures / numberOfSchedules));
};

type Props = {
	progresses: Progresses;
	className?: string;
	courseRatingUrl?: string | null;
};

const AddonContentProgress = (props: Props) => {
	const { progresses, className, courseRatingUrl } = props;
	const classes = useStyles();
	const progress = getProgress(progresses);
	return (
		<Grid
			item
			display="flex"
			className={classnames(classes.container, className)}
			alignItems="center"
		>
			<CircularProgress
				classes={{
					root: classes.progress,
					svg: classes.svg
				}}
				// we could not update the width and height using classes
				size={28}
				thickness={7}
				value={progress}
				variant="determinate"
				color="secondary"
			/>
			<Typography
				variant="h6"
				localeId="common.addon-card.progress"
				localeValues={{ progress }}
				color="secondary"
			/>
			{courseRatingUrl && <CourseRatingLink link={courseRatingUrl} />}
		</Grid>
	);
};

export default AddonContentProgress;
