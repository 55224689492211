import dayjs from 'dayjs';
import { Types } from 'common';
import { formatDateForDB } from 'components';
import getSearchQuery from './getSearchQuery';
import getTopicFilter from './getTopicFilter';
import getOrders from './getOrders';
import { DateFilterOptionEnum } from '../filters/dateFilter';
import getContentsDefinitionStartDateFilter from './getContentsDefinitionStartDateFilter';
import { ContentContentTypeFilterType } from '../../components/Filters';

/**
 * This function is useful to build the content search query. It builds the filters by
 * using some known fields like "product" and "endDateTime" but it also accepts any other filter.
 */
const getContentSearchQuery = ({
	filters,
	contentType,
	brand,
	productBrands,
	pageNumber,
	pageSize,
	date,
	topicCode,
	query,
	countryCode,
	orders,
	contentTypeFilter,
	ignoreDateFilters
}: {
	filters?: Types.SearchQuery['filters'];
	contentType?: Types.ContentType;
	brand?: Types.ProductBrand;
	productBrands?: Array<Types.ProductBrand> | null;
	pageSize: number;
	pageNumber?: number;
	date?: DateFilterOptionEnum | null;
	topicCode?: string | null;
	query?: string | null;
	countryCode: string;
	orders?: Types.SearchQuery['orders'];
	contentTypeFilter?: ContentContentTypeFilterType;
	ignoreDateFilters?: boolean;
}): { variables: { searchQuery: Types.SearchQuery } } => {
	const endDateTimeOperation =
		date === DateFilterOptionEnum.ONDEMAND
			? Types.SearchFilterOperation.LessThan
			: Types.SearchFilterOperation.GreaterThanEqual;
	const applyDateFilter = !ignoreDateFilters && date && brand !== Types.ProductBrand.Summedup;

	return {
		variables: getSearchQuery({
			pageNumber,
			pageSize,
			filters: [
				...(filters ?? []),
				{
					field: 'availableForMembers',
					operation: Types.SearchFilterOperation.Equal,
					value: ['true']
				},
				{
					field: 'publishedDomains',
					operation: Types.SearchFilterOperation.Equal,
					value: [countryCode]
				},
				// Filters
				...(contentType
					? [
							{
								field: 'contentType',
								operation: Types.SearchFilterOperation.Equal,
								value: [contentType]
							}
					  ]
					: []),
				...(productBrands
					? [
							{
								field: 'brandType',
								operation: Types.SearchFilterOperation.Equal,
								value: productBrands
							}
					  ]
					: []),
				...(applyDateFilter
					? [
							{
								field: 'endDateTime',
								operation: endDateTimeOperation,
								value: [formatDateForDB(dayjs(new Date()))]
							}
					  ]
					: []),
				...(applyDateFilter && date === DateFilterOptionEnum.ONDEMAND
					? [
							{
								field: 'isOnDemand',
								operation: Types.SearchFilterOperation.Equal,
								value: ['true']
							}
					  ]
					: []),
				...(applyDateFilter && date === DateFilterOptionEnum.FORTHCOMING
					? [
							{
								field: 'willBeBroadcasted',
								operation: Types.SearchFilterOperation.Equal,
								value: ['true']
							}
					  ]
					: []),
				...(date === DateFilterOptionEnum.ONDEMAND
					? [
							{
								field: 'willBeOnDemand',
								operation: Types.SearchFilterOperation.Equal,
								value: ['true']
							}
					  ]
					: []),
				...(getTopicFilter(topicCode) ?? []),
				...(ignoreDateFilters
					? []
					: getContentsDefinitionStartDateFilter(contentTypeFilter) ?? [])
			],
			orders: getOrders({ date, contentType, topicCode, orders }),
			query
		})
	};
};

export default getContentSearchQuery;
