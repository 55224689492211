import React from 'react';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Button } from 'components';

const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: theme.spacing(2),
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(1)
	}
}));
type CourseRatingLinkProps = {
	link: string;
};
export const CourseRatingLink = ({ link }: CourseRatingLinkProps) => {
	const handleClick = () => {
		window.open(link, '_blank');
	};
	return <Button onClick={handleClick} localeId="catalog.on-demand.rate-course" />;
};

type VideoRatingLinkProps = {
	lecture: string;
	speaker: string;
	link: string;
};
export const VideoRatingLink = ({ lecture, link, speaker }: VideoRatingLinkProps) => {
	const handleClick = () => {
		const url = new URL(link);
		url.searchParams.append('lecture', lecture);
		url.searchParams.append('speaker', speaker);
		window.open(url.toString(), '_blank');
	};

	return <Button onClick={handleClick} localeId="catalog.on-demand.rate-video" />;
};

type ContainerProps = {
	videoRatingUrl: string | null | undefined;
	lectureTitle: string | null | undefined;
	speakerName: string | null | undefined;
};
export const RatingLinksContainer = ({
	lectureTitle,
	speakerName,
	videoRatingUrl
}: ContainerProps) => {
	const classes = useStyles();
	if (videoRatingUrl) {
		return (
			<Box className={classes.container}>
				{videoRatingUrl && lectureTitle && speakerName && (
					<VideoRatingLink
						link={videoRatingUrl}
						lecture={lectureTitle}
						speaker={speakerName}
					/>
				)}
			</Box>
		);
	}
	return null;
};
