import { Types } from 'common';

export const lecturesFilters = (
	topics: Array<string>,
	isMainTopic: boolean,
	childCodes: Array<string>,
	filters: Array<Types.SearchFilter> = []
) => [
	{
		field: 'topics.code',
		operation: Types.SearchFilterOperation.Equal,
		value: topics
	},
	{
		field: 'brandType',
		operation: Types.SearchFilterOperation.NotEqual,
		value: [Types.ProductBrand.Summedup]
	},
	{
		field: 'isAvailableOnHomepage',
		operation: Types.SearchFilterOperation.Equal,
		value: ['true']
	},
	{
		field: 'willBeOnDemand',
		operation: Types.SearchFilterOperation.Equal,
		value: ['true']
	},
	...(isMainTopic && childCodes.length > 0
		? [
				{
					field: 'topics.code',
					operation: Types.SearchFilterOperation.NotEqual,
					value: childCodes
				}
		  ]
		: []),
	...filters
];
